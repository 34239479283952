.dz-max-files-reached .dropzone {
    pointer-events: none;
    cursor: default;
}

.invalid-feedback {
    display: block;
}

.dz-message {
    text-align: center;
    padding: 1.5rem;
    display: block;
    font-size: .875rem;
    font-weight: 500;
    opacity: 1;
    cursor: pointer;
    border-radius: 0.5rem;
    background: #fff;
    border: 1px dashed #dee2e6;
    transition: all .35s ease;
    color: rgba(108, 117, 125, 0.8);
    width: 100%;

    &:hover {
        background: #e9ecef;
    }
}